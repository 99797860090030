@import 'src/styles/variables';

.container {
  position: relative;
}

.list {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  max-height: pxToRem(300);
  list-style: none;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background: $site-bg;
  line-height: pxToRem(16);
  z-index: 3;
  border-radius: pxToRem(8);
  box-shadow: $box-shadow-lg;
  transition: opacity .2s ease-out;
  opacity: 0;
}

.isOpen {
  opacity: 1;
}

.largeMenu {
  padding-top: pxToRem(70);
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 0;
  max-height: initial;
  height: 100vh;

  @include breakpoints-up($tablet-sm) {
    width: calc(100% + #{pxToRem(16)});
    left: pxToRem(-8);
    height: initial;
    max-height: pxToRem(300);
    position: absolute;
    border-radius: pxToRem(8);
  }
}

.searchContainer {
  position: relative;

  @include breakpoints-down($tablet-sm) {
    flex: 1;
  }
}

.processing {
  &:after {
    display: block;
    position: absolute;
    content: '';
    width: pxToRem(20);
    height: pxToRem(20);
    right: pxToRem(10);
    top: 50%;
    margin-top: pxToRem(-10);
    background: url('../../../public/loader.gif');
  }
}

.noResultsText {
  color: $black;
  padding: pxToRem(15) pxToRem(20) 0;
  line-height: pxToRem(20);
  font-size: 14px;
}

.form {
  position: relative;
  z-index: 4;
  transition: all .3s ease-out;

  &::before {
    position: absolute;
    background: $site-bg;
    content: '';
    opacity: 0;
    width: calc(100% + #{pxToRem(16)});
    height: calc(100% + #{pxToRem(16)});
    left: pxToRem(-8);
    border-radius: pxToRem(8);
    top: pxToRem(-8);

    @include breakpoints-down($tablet-sm) {
      height: pxToRem(80);
      top: 0;
      left: 0;
      width: 100%;
      position: fixed;
      border-radius: 0;
    }
  }

  @include breakpoints-down($tablet-sm) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.largeMenuForm {
  &::before {
    opacity: 1;
  }

  @include breakpoints-down($tablet-sm) {
    position: fixed;
    right: pxToRem(20);
    top: pxToRem(19);
    width: calc(100% - #{pxToRem(36)});
    display: flex;
    align-items: center;
  }
}

.listOpen {
  padding-bottom: pxToRem(32);
}

.listItem {
  overflow: hidden;
  position: relative;
  margin: pxToRem(22) pxToRem(18) pxToRem(12);
  user-select: none;

  > div {
    font-size: pxToRem(14);
    padding-right: pxToRem(12);
    background: $site-bg;
    display: inline-block;
    position: relative;
    z-index: 1;
    color: $font-dark;
  }

  &:before {
    content: '';
    background: $border-gray;
    position: absolute;
    z-index: 0;
    height: pxToRem(1);
    width: 100%;
    left: 0;
    top: pxToRem(8);
  }

  @include breakpoints-up($tablet-sm) {
    margin: pxToRem(22) pxToRem(52) pxToRem(12);
  }
}

.close {
  padding: pxToRem(10) pxToRem(3) pxToRem(10) pxToRem(16);
  z-index: 0;
  cursor: pointer;
  color: white;
  font-weight: bold;

  &:focus {
    outline: none;
  }
}

.collctivClose {
  padding: pxToRem(10) pxToRem(3) pxToRem(10) pxToRem(10);
  z-index: 0;
  cursor: pointer;
  color:var(--portalColor);
  font-weight: bold;

  &:focus {
    outline: none;
  }
}


.closePortalColor {
  color: var(--portalColor);
}

.searchButton {
  z-index: 1;
  cursor: pointer;
  padding: pxToRem(10);
  font-size: pxToRem(18);

  &:focus {
    outline: none;
  }

  @include breakpoints-up($tablet-sm) {
    display: none;
  }
}