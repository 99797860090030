@import './src/styles/variables';

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.inputIcon {
  position: absolute;
  left: pxToRem(16);
  pointer-events: none;
  user-select: none;
  font-size: pxToRem(18);
}

.input {
  width: 100%;
  padding: pxToRem(10) pxToRem(16) pxToRem(10) pxToRem(44);
  border: 0;
  background-color: $site-bg;
  border-radius: pxToRem(8);
  border: pxToRem(3) solid;
  color: $font-dark;
  font-size: pxToRem(18);
  line-height: pxToRem(28);

  &:focus {
    outline: 0;
  }

  &::placeholder{
    color: #242323;
  }
}