$black: #212121;
$orange: #ffcf31;
$dark-green: #16282d;
$dark-blue: #3b598d;

$font-light: #ffffff;
$font-gray: #939393;
$font-dark: #595959;
$font-darker: $black;
$font-gray: #bdbdbd;

$site-bg: #ffffff;

$border-gray: #e6e6e6;

$box-shadow-sm: 0 pxToRem(1) pxToRem(3) 0 rgba(48, 49, 51, 0.1), 0 0 pxToRem(1) 0 rgba(48, 49, 51, 0.05);
$box-shadow-md: 0 pxToRem(8) pxToRem(16) 0 rgba(48, 49, 51, 0.1), 0 0 pxToRem(1) 0 rgba(48, 49, 51, 0.05);
$box-shadow-lg: 0 pxToRem(16) pxToRem(24) 0 rgba(48, 49, 51, 0.1), 0 0 pxToRem(1) 0 rgba(48, 49, 51, 0.05);