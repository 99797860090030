@import 'src/styles/variables';

.siteCookieBanner {
  line-height: 1.618;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  direction: ltr;
  text-align: left;
  transition: all 0.25s;
  box-sizing: border-box;
  z-index: 99999;
  overflow: hidden;
  color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #464646;
  padding: 15px 20px 15px;
  opacity: 1;
  max-height: 999px;
  min-height: 30px;
}

.innerCookieBanner {
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.innerCookieBannerMoreInfoLink {
  text-decoration: underline;
  color: #fff;
}

.closeCookie {
  font: inherit;
  overflow: visible;
  text-transform: none;
  vertical-align: baseline;
  text-shadow: none;
  display: inline-block;
  outline: 0;
  font-size: 18px;
  font-family: inherit;
  letter-spacing: 0px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  background: #5cb615;
  border: 0;
  padding: 6px 9px;
  border-radius: 3px;
  margin: 0 0 0 20px;
  transition: transform 0.3s ease-out;

  &:focus {
    outline: none;
  }

&:focus-visible {
  outline: 2px solid #fff; // High contrast outline
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.8);
}

}

.rejectCookie {
  font: inherit;
  overflow: visible;
  text-transform: none;
  vertical-align: baseline;
  text-shadow: none;
  display: inline-block;
  outline: 0;
  font-size: 18px;
  font-family: inherit;
  letter-spacing: 0px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  background: #c02f4d;
  border: 0;
  padding: 6px 9px;
  border-radius: 3px;
  margin: 0 0 0 20px;
    transition: transform 0.3s ease-out;

  &:focus {
    outline: none;
  }

&:focus-visible {
  outline: 2px solid #fff; // High contrast outline
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.8);
}

}

.privacyPolicy {
  padding: 6px 15px;
  font-size: 18px;
  font-family: inherit;
  letter-spacing: 0px;
  line-height: 18px;
  font-style: normal;
}

