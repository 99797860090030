@use '~flexbox-grid-mixins/dart-sass/flexbox-grid-mixins';

.row {
  @include flexbox-grid-mixins.grid($flex-wrap: wrap);
}

.row-gutter {
  @include flexbox-grid-mixins.grid($flex-wrap: wrap);
  margin-left: pxToRem(-12);
  margin-right: pxToRem(-12);
}

.col-gutter {
  padding-left: pxToRem(12);
  padding-right: pxToRem(12);
}

.col-1 {
  @include flexbox-grid-mixins.grid-col($col: 1, $min-width: true);
}

.col-2 {
  @include flexbox-grid-mixins.grid-col($col: 2, $min-width: true);
}

.col-3 {
  @include flexbox-grid-mixins.grid-col($col: 3, $min-width: true);
}

.col-4 {
  @include flexbox-grid-mixins.grid-col($col: 4, $min-width: true);
}

.col-5 {
  @include flexbox-grid-mixins.grid-col($col: 5, $min-width: true);
}

.col-6 {
  @include flexbox-grid-mixins.grid-col($col: 6, $min-width: true);
}

.col-7 {
  @include flexbox-grid-mixins.grid-col($col: 7, $min-width: true);
}

.col-8 {
  @include flexbox-grid-mixins.grid-col($col: 8, $min-width: true);
}

.col-9 {
  @include flexbox-grid-mixins.grid-col($col: 9, $min-width: true);
}

.col-10 {
  @include flexbox-grid-mixins.grid-col($col: 10, $min-width: true);
}

.col-11 {
  @include flexbox-grid-mixins.grid-col($col: 11, $min-width: true);
}

.col-12 {
  @include flexbox-grid-mixins.grid-col($col: 12, $min-width: true);
}

// tablet sm

.col-tablet-sm-6 {
  @include breakpoints-up($tablet-sm) {
    @include flexbox-grid-mixins.grid-col($col: 6, $min-width: true);
  }
}

// tablet lg

.col-tablet-lg-2 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 2, $min-width: true);
  }
}

.col-tablet-lg-3 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 3, $min-width: true);
  }
}

.col-tablet-lg-4 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 4, $min-width: true);
  }
}

.col-tablet-lg-5 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 5, $min-width: true);
  }
}

.col-tablet-lg-7 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 7, $min-width: true);
  }
}

.col-tablet-lg-8 {
  @include breakpoints-up($tablet-lg) {
    @include flexbox-grid-mixins.grid-col($col: 8, $min-width: true);
  }
}

// desktop sm

.col-desktop-sm-1 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 1, $min-width: true);
  }
}

.col-desktop-sm-2 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 2, $min-width: true);
  }
}

.col-desktop-sm-3 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 3, $min-width: true);
  }
}

.col-desktop-sm-4 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 4, $min-width: true);
  }
}

.col-desktop-sm-5 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 5, $min-width: true);
  }
}

.col-desktop-sm-6 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 6, $min-width: true);
  }
}

.col-desktop-sm-7 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 7, $min-width: true);
  }
}

.col-desktop-sm-8 {
  @include breakpoints-up($desktop-sm) {
    @include flexbox-grid-mixins.grid-col($col: 8, $min-width: true);
  }
}

// desktop lg

.col-desktop-lg-3 {
  @include breakpoints-up($desktop-lg) {
    @include flexbox-grid-mixins.grid-col($col: 3, $min-width: true);
  }
}

.col-desktop-lg-4 {
  @include breakpoints-up($desktop-lg) {
    @include flexbox-grid-mixins.grid-col($col: 4, $min-width: true);
  }
}

.col-desktop-lg-7 {
  @include breakpoints-up($desktop-lg) {
    @include flexbox-grid-mixins.grid-col($col: 7, $min-width: true);
  }
}

.col-desktop-lg-8 {
  @include breakpoints-up($desktop-lg) {
    @include flexbox-grid-mixins.grid-col($col: 8, $min-width: true);
  }
}