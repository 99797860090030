@import './src/styles/variables';

.listItem {
  cursor: pointer;
  overflow: hidden;
  padding: 0 pxToRem(18);
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    z-index: 0;
    left: 0;
    opacity: 0.25;
    pointer-events: none;
  }

  &:hover {
    &:before {
      background: var(--portalColor);
    }
  }

  p {
    text-decoration: none;
    padding: pxToRem(8) 0;
    display: block;
    overflow: hidden;
  }

  @include breakpoints-up($tablet-sm) {
    padding: 0 pxToRem(52);
  }
}

.listItemText {
  display: block;
  font-weight: normal;
  color: $black;
  font-size: pxToRem(18);
  line-height: pxToRem(28);
}

.linkWrapper {
  &:focus {
    outline: none;
  }
}