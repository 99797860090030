$phone-sm: 320;
$phone-lg: 600;
$tablet-sm: 768;
$tablet-lg: 920;
$desktop-sm: 1200;
$desktop-lg: 1440;
$desktop-xl: 1920;

@mixin breakpoints-up($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin breakpoints-down($screen) {
  @media (max-width: $screen - 1 +'px') {
    @content;
  }
}