@import 'src/styles/variables';

.footer {
  background: $black;
  width: 100%;
  padding: pxToRem(78) 0 pxToRem(72);
}

.footerMenu {
  display: flex;
  align-items: center;
  flex-direction: column;

  & > a {
    display: block;
    color: $font-light;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  & > a:not(:last-child) {
    margin-bottom: pxToRem(24);

    @include breakpoints-up($tablet-lg) {
      margin-right: pxToRem(40);
      margin-bottom: 0;
    }
  }

  @include breakpoints-up($tablet-lg) {
    flex-direction: row;
  }

  @include breakpoints-up($tablet-sm) {
    flex: 1;
  }

  @include breakpoints-down($desktop-sm) {
    justify-content: center;
    flex: 1 100%;
  }
}

.copyright {
  font-size: pxToRem(12);
  padding-top: pxToRem(24);
  color: $font-gray;

  @include breakpoints-down($desktop-sm) {
    flex: 1 100%;
    text-align: center;
    margin-top: pxToRem(40);
  }
}

.socialMedia {
  flex: 0;
  display: flex;
  justify-content: flex-end;

  a:not(:last-child) {
    margin-right: pxToRem(36);
  }

  @include breakpoints-down($desktop-sm) {
    flex: 1 100%;
    justify-content: center;
    margin-top: pxToRem(40);
  }
}

.socialMediaIcon {
  font-size: pxToRem(20);
  transition: all .3s ease-out;
}

.socialMediaIcon:hover {
  opacity: 0.9;
}

.footerWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}