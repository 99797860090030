@import 'src/styles/variables';

.siteHeader {
  padding: pxToRem(18) 0 pxToRem(14);
  z-index: 5;
}

.innerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoints-down($tablet-sm) {
    flex-wrap: nowrap;
  }
}

.logoContainer {
  z-index: 5;

  img {
    display: block;
  }
}

.search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;

  > div {
    padding-left: pxToRem(16);
    width: 100%;
    position: relative;

    &::after {
      content: '';
      width: pxToRem(1);
      height: 100%;
      position: absolute;
      margin-left: pxToRem(48);
      background-color: rgba(255, 255, 255, 0.4);
      right: pxToRem(-48);
      top: 0;

      @include breakpoints-down($tablet-sm) {
        display: none;
      }
    }

    @include breakpoints-up($tablet-sm) {
      max-width: pxToRem($tablet-sm);
      margin-right: pxToRem(96);
      padding: 0 0 0 pxToRem(32);
    }
  }

  @include breakpoints-up($tablet-sm) {
    justify-content: center;
  }
}

.navButton {
  @include breakpoints-down($tablet-sm) {
    display: none;
  }
}

.cartButton {
  margin-left: 10px !important;
  background-color: black !important;
  border-radius: 5px !important;
  color: white !important;
  border: black 2px solid !important;
  > span {
    gap: 5px;
  }
}
