@import 'src/styles/variables';

.button {
  display: flex;
  justify-content: space-between;
  border-radius: pxToRem(4);
  color: $font-light;
  font-weight: bold;
  font-size: pxToRem(16);
  line-height: 1.5;
  padding: pxToRem(8) pxToRem(16);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition: all .3s ease-out;
  flex: 0 0 auto;
  background-color: var(--portalColor);
  border: pxToRem(2) solid var(--portalColor);

  &:hover {
    opacity: .7;
  }
}

.outlined {
  border: pxToRem(2) solid $font-light;
}

.white {
  background: $site-bg;
  box-shadow: $box-shadow-md;
  color: var(--portalColor);
  border-color: transparent;
}

.black {
  background: $black;
  box-shadow: $box-shadow-md;
  color: $site-bg;
  border-color: transparent;
}

.icon {
  margin-left: pxToRem(14);

  svg {
    height: pxToRem(14);
  }
}